@import "~antd/dist/antd.css";
@import "react-contexify/dist/ReactContexify.css";

.main-layout {
	background: #fff;
}

.main-content {
	background: #fff;
}

.content-flex-horizontal {
	display: flex;
	flex-flow: row nowrap;
	column-gap: 32px;
	height: 100%;
}

.subcontent-flex-vertical {
	display: flex;
	flex-flow: column nowrap;
	row-gap: 16px;
}

#root .ant-list-item-action > li,
#root .ant-list-item-meta-description {
	color: rgba(0, 0, 0, 0.7);
}

body .ant-drawer {
	z-index: 999;
}

.react-contexify {
	z-index: 1001 !important;
}

.field-error .ant-input,
.field-error .ant-input-number,
.field-error .ant-select:not(.ant-select-customize-input) .ant-select-selector {
	border-color: red;
}

.hover-list-item {
	padding: 3px;
	cursor: default;
}

.hover-list-item.selected {
	border: 1px solid black;
}

.hover-list-item:hover {
	background: lightgray;
}
