#calendar {
	user-select: none;
	height: 100%;
	flex-flow: column;
	display: flex;
}

.fc .fc-timegrid-event .fc-event-main {
	padding: 0;
}

.fc-event {
	cursor: pointer;
}

.fc .fc-timegrid-slots .fc-timegrid-slot {
	height: 60px;
}

.fc tr:first-child.fc-scrollgrid-section .fc-scroller {
	max-height: 180px;
}

.fc tr:first-child.fc-scrollgrid-section-body {
	background: lightblue;
}

.opaque {
	opacity: 1 !important;
}

.calendar-event-header {
	background-color: hsl(300, 100%, 23%);
	padding: 0px 5px 2px 5px;

	border-radius: 3px 3px 0px 0px;
	border-width: 4px 0px 0px 0px;
	border-color: #520052;
	border-style: solid;
	white-space: nowrap;
}

.calendar-event-content {
	background-clip: content-box;
	background-color: #86c3ff;
	height: 100%;
}

.calendar-event-content-entry {
	padding: 1px 4px;
	overflow: hidden;
	white-space: nowrap;
}

.fc .fc-v-event {
	border: none;
}

.classroom-view-bg {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	background-color: rgba(0.1, 0.1, 0.1, 0.3);
	z-index: 5;
}

.classroom-view-modal {
	border: 4px solid gray;
	border-radius: 16px;
	max-width: 500px;
	padding: 20px;
	background-color: white;

	display: flex;
	flex-direction: column;
}

.classroom-view-section-grid {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	flex-grow: 1;
	overflow: hidden;
	gap: 16px 16px;
}

.classroom-view-section-item {
	width: 140px;
	background-color: darkblue;
	flex-grow: 1;
	border-radius: 2px;
}
